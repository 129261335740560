import '../css/Terms.css'
import close from '../image/cross.png'

const Terms = props => {

    return (
        <div className='contTerms'>
            <h2>Условия использования сервиса <br/>UDocument.online:</h2>
            <img onClick={props.onClick} src={close} alt='x' className='termsImg' />
            <ul>
                <li  className='termsLi'>При использовании сайта UDocument.online, пользователь соглашается со всеми условиями использования.</li>
                <li className='termsLi'>Все материалы на сайте UDocument.online, включая тексты, изображения и видео, защищены авторским правом и являются собственностью компании, за исключением случаев, когда это указано иначе.</li>
                <li className='termsLi'>Никакая часть материалов на сайте UDocument.online не может быть скопирована, воспроизведена, передана или распространена без предварительного письменного разрешения компании.</li>
                <li className='termsLi'>Компания не несет ответственности за любые прямые, косвенные, случайные или последующие убытки, которые могут возникнуть в результате использования или невозможности использования сайта UDocument.online.</li>
                <li className='termsLi'>Компания не использует и не хранит персональные данные пользователей, все введенные на сайте персональные данные недоступны для компании.</li>
                <li className='termsLi'>Компания оставляет за собой право в любое время изменять условия использования сайта UDocument.online без предварительного уведомления пользователей.</li>
                <li className='termsLi'>Все споры, связанные с использованием сайта UDocument.online, будут рассмотрены в соответствии с законодательством Российской Федерации.</li>
                <li className='termsLi'>Пользователь обязуется не использовать сайт UDocument.online для нарушения прав третьих лиц или законодательства.</li>
                <li className='termsLi'>Компания не несет ответственности за содержание материалов, размещенных пользователями на сайте UDocument.online.</li>
                <li className='termsLi'>Компания не гарантирует точность, полноту или актуальность материалов, размещенных на сайте UDocument.online.</li>
                <li className='termsLi'>Пользователь не имеет права использовать автоматизированные средства для сбора информации с сайта UDocument.online без предварительного разрешения компании.</li>
                <li className='termsLi'>Компания оставляет за собой право в любое время приостановить или прекратить доступ пользователя к сайту UDocument.online без предварительного уведомления.</li>
                <li className='termsLi'>Если какое-либо положение условий использования будет признано недействительным или неприменимым, остальные положения сохранят свою силу и эффективность.</li>
                <li className='termsLi'>Если пользователь не согласен со всеми условиями использования, он должен немедленно прекратить использование сайта UDocument.online.</li>
                {/* <li className='termsLi'>Услугу оказывает: Сушкова Татьяна Сергеевна</li> */}
                <li className='termsLi'>По всем вопросам пишите на электронную почту: support@udocument.online</li>
                <li className='termsLi'>Услуги оказывает
                    <p style={{color:"black", margin:'0'}}> - Сушковa Татьяна Сергеевна</p>
                     <p style={{color:"black", margin:'0'}}> - ИНН: 100117257353</p>
                     <p style={{color:"black", margin:'0'}}> - Адрес: г. Костомукша ул. Октябрьская д.2 п.7</p>
                     <p style={{color:"black", margin:'0'}}> - Эл. почта: support@udocument.online</p>
                     <p style={{color:"black", margin:'0'}}> - тел. +7(995)-171-3100</p>
                </li>
            </ul>
            <h3>Стоимость и порядок получения услуги: </h3>
            <ul>
                <li  className='termsLi'>Для подготовки документа пользователю необходимо:
                <p style={{color:"black", margin:'0'}}> - Выбрать нужный документ из списка;</p>
                <p style={{color:"black", margin:'0'}}> - Заполнить все поля предлагаемой формы;</p>
                <p style={{color:"black", margin:'0'}}> - Нажать на кнопку "Скачать документ";</p>
                <p style={{color:"black", margin:'0'}}> - Произвести оплату;</p>
                <p style={{color:"black", margin:'0'}}> - После оплаты, документ будет готов для загрузки;</p>
                </li>
                <li  className='termsLi'>Стоимость подготовки любого документа составляет 499 рублей.</li>
                <li  className='termsLi'>Документ подготавливается в элкетронном виде, и готов к загрузке сразу же после завершения оплаты.</li>
            </ul>
        </div>
    )
}

export default Terms