import { Link } from 'react-router-dom'
import '../css/Block.css'
import prikaz from '../image/prikaz.jpg'
import programm from '../image/contractAuto.jpeg'
import place from '../image/contractLoan.jpg'
import quik from'../image/sudDevorse.jpg'
import restr from'../image/restr.jpg'
import cancel from'../image/cancelDecission.jpg'
import insurance from'../image/insurance.jpeg'
import iskLoan from'../image/iskLoan.webp'



const Block = () => {

const arrObject =   [
    {
        photo: prikaz,
        discribe: 'Отмена судебного приказа',
        link: '/document/1'
    },
    {
        photo: programm,
        discribe: 'Договор купли-продажи автомобиля',
        link: '/document/2'
    },
    {
        photo: place,
        discribe: 'Договор займа денег',
        link: '/document/3'
    },
    {
        photo: quik,
        discribe: 'Исковое заявление на развод',
        link: '/document/4'
    },
    {
        photo: restr,
        discribe: 'Заявление на реструкт. задолженности',
        link: '/document/9'
    },
    {
        photo: cancel,
        discribe: 'Отмена заочного решения суда по кредиту',
        link: '/document/11'
    },
    {
        photo: insurance,
        discribe: 'Отмена страховки',
        link: '/document/10'
    },
    {
        photo: iskLoan,
        discribe: 'Иск на возврат долга',
        link: '/document/7'
    },
]

    const CardDoc = (props) => {
        const ind = props.data
        return (
            <>
            <Link target="_blank" to={ind.link} style={{textDecoration:'none'}}>
            <article  className="card">
            <div style={{backgroundImage:'url('+ind.photo+')', backgroundSize:'cover', borderRadius:'35px',  boxShadow: '0 0 5px 2px rgba(0, 0, 0, 0.05)'}}>
            <div className="img"/>
            </div>
            <div className="content">
                <p className='discribeBlock'>{ind.discribe}</p>
            </div>
            </article>
            </Link>
            </>
            
        )
    }

    return (
        <div style={{marginTop:'40px'}}>
        <p style={{color:'#000000', marginLeft:'10px'}}>Популярные запросы:</p>
        
        <div className="cards" style={{gridTemplateColumns:'repeat(auto-fill, minmax(100px, 1fr))'}} >
            {arrObject.map(el=>
            <CardDoc key={arrObject.indexOf(el)} data={el}></CardDoc>
            )}
        </div>
        <Link to='/alldocuments' style={{textDecorationColor:'#000000'}}>
          <p style={{color:'#000000', marginLeft:'10px', marginTop:'0px'}}>Больше документов</p>
      </Link>
        </div>
    )

}

export default Block