import { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { YMInitializer} from 'react-yandex-metrika';
import '../css/Article.css'
import CodeEditor from './CodeEditor'
import RightArticle from './RightArticle'


const AllArticle = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  })
    

  return (
    <div>
       <YMInitializer accounts={[93382595]} options={{webvisor: true, ecommerce:"dataLayer", clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
      <div className='articleCont'>
      <div className='leftArticle'>
          <Link to='/' style={{textDecoration:'none'}}>
      <h1 className='headLeft'>UDocument</h1>
      <p style={{margin:'0', color:'#666666', fontSize:'15px',fontWeight:'bolder' }}>Создание юридических документов онлайн</p>
      </Link>
      <RightArticle />
      </div>
      <div className="articleRight">
       <CodeEditor/>
    </div>
    </div>
    </div>
  );
}

export {AllArticle};