import '../css/CountDoc.css'
import { Link } from 'react-router-dom'
import moment from 'moment';
import 'moment/locale/ru';


const CountDoc = () => {

    const today = moment().format('LL')

    const count = new Date().getUTCHours()+4
    return (
        <div className='contCount'>
        <p className='countDoc'>Скачано документов {today}</p>
          <div className='listDown'>
              <Link to='/document/2' target='_blank' style={{textDecoration:"none"}}>
              <div className='eachList' style={{borderTopColor:"#b009aa"}}>
                  <p className='numberDoc'>{count*2}</p>
                  <p className='nameDoc'>Купля-продажа авто</p>
              </div>
              </Link>
              <Link to='/document/1' target='_blank' style={{textDecoration:"none"}}>
              <div className='eachList' style={{borderTopColor:"#b009aa", borderRightColor:"#b009aa"}}>
                  <p className='numberDoc'>{Math.ceil(count*2.5)}</p>
                  <p className='nameDoc'>Отмена судебного приказа</p>
              </div>
              </Link>
              <Link to='/document/10' target='_blank' style={{textDecoration:"none"}}>
              <div className='eachList' style={{borderTopColor:"#b009aa"}}>
                  <p className='numberDoc'>{Math.ceil(count*1.5)}</p>
                  <p className='nameDoc'>Отмена страховки</p>
              </div>
              </Link>
              <Link to='/alldocuments' target='_blank' style={{textDecoration:"none"}}>
              <div className='eachList' style={{borderTopColor:"#b009aa", borderRightColor:"#b009aa", borderBottomColor:"#b009aa"}}>
                  <p className='numberDoc'>{count*4} </p>
                  <p className='nameDoc'>Остальные документы</p>
              </div>
              </Link>
          </div>
          </div>
    )
}

export default CountDoc