import { Link } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika';
import { useEffect } from 'react'
import '../css/Article.css'
import CodeEditor from './CodeEditor'


const Article = props => {
  useEffect(() => {
    window.scrollTo(0, 0);})

  const idC =  props.data
  //Преобразование текста, чтобы каждое предложение начиналось с новой строки
  const text = idC.content
  const sentences = text.split('. ');
  const textWithBreaks = sentences.map((sentence, index) => {
    if (index === sentences.length - 1) {
      return sentence; // не добавляем <br> после последнего предложения
    }
    return sentence + ".<br>";
  });


  return (
    <div className='contArticle'>
       <YMInitializer accounts={[93382595]} options={{webvisor: true, ecommerce:"dataLayer", clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
      <div className='articleCont'>
        <div className='leftArticle'>
          <Link to='/' style={{textDecoration:'none'}}>
      <h1 className='headLeft'>UDocument</h1>
      <p style={{margin:'0', color:'#666666', fontSize:'15px',fontWeight:'bolder' }}>Создание юридических документов онлайн</p>
      </Link>
      <div className='infoArticle'>
      <h1 style={{marginBottom:'25px', fontSize:'24px'}}>{idC.title}</h1>
      <img style={{width:'100%', height:'100%', margin:'auto', borderRadius:'35px'}} src={`/image/${idC.img}`} alt='?'/> 
      <p style={{color:'#666666', fontSize:'18px'}} dangerouslySetInnerHTML={{ __html: textWithBreaks.join("") }}></p>
      </div>
      </div>
      <div className="articleRight">
       <CodeEditor/>
    </div>
    </div>
    </div>
  );
}

export {Article};