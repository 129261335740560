import { Link } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika';
import { useEffect, useState} from 'react'
import { useLocation } from 'react-router';
import '../css/Article.css'
import CodeEditor from './CodeEditor'
// import { useLocation } from "react-router";



const DocumentFormPaid = () => {
const [component, setComponent] = useState(null);
const location = useLocation()

// const [infoPrikaz, setInfoPrikaz] = useState(null)
// const [paid, setPaid] = useState(false)

const searchParams = new URLSearchParams(location.search);
const paid = searchParams.get('paid')
const infoPrikaz = JSON.parse(localStorage.getItem('data'))

useEffect(() => {
  //Проверка, если компонент подтянулся, значит не выполняем снова поиск компанента
  if (!component) {
    async function loadComponent() {
      const { default: DynamicComponent } = await import(`./${infoPrikaz.nameElement}`);
      setComponent(() => <DynamicComponent data={infoPrikaz} {...infoPrikaz.nameElement}/>);
    }
    loadComponent();
  }

  window.scrollTo(0, 0);
}, [component, infoPrikaz]);


if (infoPrikaz && paid){
  return (
    <div className='contArticle'>
       <YMInitializer accounts={[93382595]} options={{webvisor: true, ecommerce:"dataLayer",clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
      <div className='articleCont'>
      <div className='leftArticle'>
          <Link to='/' style={{textDecoration:'none'}}>
      <h1 className='headLeft'>UDocument</h1>
      <p style={{margin:'0', color:'#666666', fontSize:'13px',fontWeight:'bolder' }}>Создание юридических документов онлайн</p>
      </Link>
      <div className='docPaidCont'>
      <h2 style={{}}>Оплата прошла успешно</h2>
      <ul>
        <li style={{color:'#666666', fontSize:'18px'}}>Ваш документ готов.</li>
        <br/>
        <li style={{color:'#666666', fontSize:'18px'}}>Нажмите на кнопку "Скачать" и документ будет сохранен на Ваше устройство.</li>
        <br/>
        <li style={{color:'#666666', fontSize:'18px'}}>Если вы не видите Ваш документ, посмотрите в "Загрузках" в браузере.</li>
        <br/>
        <li style={{color:'#666666', fontSize:'18px'}}>Если у Вас возникли сложности со скачиванием документа, напишите нам письмо с описанием проблемы на электронную почту: support@udocument.online и мы решим Вашу проблему в кротчайшие сроки.</li>
      </ul>
      {/* <p style={{color:'#666666', fontSize:'18px'}}>Нажмите на кнопку "Скачать" и готовый документ будет сохранен на Ваше устройство. <br/><br/>Если вы не видите Ваш документ, посмотрите в "Загрузках" в браузере. <br/><br/>После скачивания, откройте документ, и следуйте инструкциям в приложении к документу.</p> */}
      </div>
      {infoPrikaz ? component: <div>Loading...</div> }
      </div>
      <div className="articleRight">
       <CodeEditor/>
    </div>
    </div>
    </div>
  );
}else {
    return (
        <div>
            <h1>Loading...</h1>
        </div>
    )
}
}

export {DocumentFormPaid};