import React, { useState} from "react";
import '../css/DocumentForm.css'
// import  ym  from 'react-yandex-metrika';

const DocumentForm = props => {

const [visModalPay, setVisModalPay] = useState(false)
//Точки в конце "Переводим на страницу оплаты"
const [dots, setDots] = useState(0);

// ИД заказа для магазина
const payID = (Date.now()/100).toFixed(0)
const price = props.data.price

let fields=''
let header = ''
  if(props.data) {
    fields = props.data.input.fields
    header = props.data.h1
  } else {
    fields = ''
    header = ''
  }
  const [infoPrikaz, setInfoPrikaz] = useState(fields)

  function isObjectComplete(obj) {
    for (const key in obj) {
      if (obj[key] === '') {
        return false;
      }
    }
    return true;
  }
  const isComplete = isObjectComplete(infoPrikaz);

// Проверка заполнения каждый 3 полей
const [sliceCount, setSliceCount] = useState(3)

const addFields = () =>{
  if (sliceCount<Object.keys(infoPrikaz).length){
  setSliceCount(Number(sliceCount+5))}
  setInfoPrikaz({...infoPrikaz, nameElement: props.data.nameElement})
}
//Видимость кнопки Дальше
  function seeVisible(){
    if ((sliceCount<Object.keys(infoPrikaz).length)){
      return true
    } return false
  }
  const isVisible = seeVisible()

  //Функция изменения Инпут
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInfoPrikaz({ ...infoPrikaz, [name]: value })};

// Получаем URL главной страницы, чтобы с магазина перенаправлять на него (убираем проблемы с www)
const urlHome = window.location.origin
const urlPaid = `${urlHome}/successpaid?paid=true`

// При нажатии кнопки, объект из инпут, преобразуем в строку и записываем в браузер, в форме данные будут извлечены
const onPress = () => {
  localStorage.setItem('data', JSON.stringify(infoPrikaz));
  setVisModalPay(true)
  setDots(0);
  
  //Имитация задержки
  const intervalId = setInterval(() => {
    setDots((prevDots) => prevDots < 5 ? prevDots + 1 : 0);
  }, 300);
  setTimeout(() => {
    clearInterval(intervalId);
  }, 10000);
}
  
  if (infoPrikaz || price) {
  return (
    <div style={{paddingTop:'10px'}}>
        <h2>{header}</h2>
        {Object.keys(props.data.input.labels).slice(0, sliceCount).map((key) => (
        <div key={key}>
          <label htmlFor={key}>{props.data.input.labels[key]}
          <input
            className='inpCalc1'
            type="text"
            id={key}
            name={key}
            placeholder={props.data.input.placeHolders[key]}
            value={infoPrikaz[key]}
            onChange={handleInputChange}
          />
          </label>
        </div>
    ))}
  {visModalPay ? <div className='madalPaid'>
    <p className="modalText">Переводим на страницу оплаты{'.'.repeat(dots)}</p>
  </div>:<div/>}
    
  {isVisible ? (
  <button className='addBtn' onClick={addFields}>
    Дальше
  </button>
) : isComplete ? (
    <div>
    <form method="post" action="https://www.payanyway.ru/assistant.htm">
           <button onClick={() => {window.ym(93382595, 'reachGoal', 'composite');onPress() }}  className='addBtn'>Cкачать документ</button>
            <input type="hidden" name="MNT_ID" value="15649680"/>
            <input type="hidden" name="MNT_TRANSACTION_ID" value={payID}/>
            <input type="hidden" name="MNT_TEST_MODE" value="0"/>
            <input type="hidden" name="MNT_CURRENCY_CODE" value="RUB"/>
            <input type="hidden" name="MNT_AMOUNT" value={price}/>
            <input type="hidden" name="MNT_SUCCESS_URL" value={urlPaid}/>
            <input type="hidden" name="MNT_INPROGRESS_URL" value={urlPaid}/>
            <input type="hidden" name="MNT_RETURN_URL" value='https://udocument.online'/>
       </form>
    </div>
  
  || <div>Loading...</div>
) : (
  <p style={{ color: '#b009aa' }}>Проверьте заполение всех полей.</p>
)}

    </div>
  );}
  else {
    return (
      <div>Loading...</div>
    )
  }
}

export default DocumentForm;