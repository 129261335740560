import '../css/AppCont.css'
import { YMInitializer } from 'react-yandex-metrika';
import CodeEditor from './CodeEditor'
import LeftHome from './LeftHome';


function AppCont() {

  return (
    <div>
       <YMInitializer accounts={[93382595]} options={{webvisor: true, ecommerce:"dataLayer", clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
      <div className='appHome'>
        <div className='leftHomeCont'>
        <LeftHome />
        </div>
        <div className='codeEditorCont'>
        <CodeEditor/>
        </div>
      </div>
    </div>
  );
}

export {AppCont};