import { Link } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika';
import { useEffect} from 'react'
import '../css/Article.css'
import CodeEditor from './CodeEditor';
import DocumentForm from './DocumentForm'
import { Helmet, HelmetProvider} from 'react-helmet-async';
import { LoadingScreen } from './LoadingScreen';
import CountDoc from './CountDoc';

const CommonForm = props => {

  // const [component, setComponent] = useState(null);
  const handleScroll = (event) => {
    event.preventDefault();

    const targetId = event.target.getAttribute("href");
    const targetElement = document.querySelector(targetId);
  
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block:'start',
      });
    }
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
    
  // Код для динамического подтягивания элементов
    // async function loadComponent() {
    //     const { default: DynamicComponent } = await import(`./${props.data.nameElement}`);
    //     setComponent(() => <DynamicComponent {...props.data.nameElement}/>);
    //   }
    //   loadComponent();
    // }, [props.data.nameElement]);
// Конец кода
  })
  if (!props.data) {
      return (
        <div ></div>
      )
    }
    else {

  const idC =  props.data
  //Преобразование текста, чтобы каждое предложение начиналось с новой строки
  const text = idC.content
  const sentences = text.split('. ');
  const textWithBreaks = sentences.map((sentence, index) => {
    if (index === sentences.length - 1) {
      return sentence; // не добавляем <br> после последнего предложения
    }
    return sentence + ".<br>";
  });

  let marginDiv = '10px'
  if (idC.refTitle) {
    marginDiv = '10px'
  }

  if (props.data){
  return (
    <HelmetProvider>
    <div>
       <YMInitializer accounts={[93382595]} options={{webvisor: true, ecommerce:"dataLayer",clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
       <Helmet>
          <title>{idC.titleSeo}</title>
          <meta name="description" content={idC.discSeo} />
        </Helmet>
      <div className='articleCont'>
        <div className='leftArticle'>
          <div className='headCommon'>
          <Link to='/' style={{textDecoration:'none'}}>
          <h1 className='headLeft' >UDocument</h1>
                 <p style={{margin:'0', color:'#666666', fontSize:'15px',fontWeight:'bolder' }}>Создание юридических документов онлайн</p>
          </Link>
    {idC.refTitle &&
          <div className='headLink'>
          <a style={{textDecorationColor:"#666666", color:'#666666'}} target='_blank' rel="noreferrer" href={idC.refUrl}>{idC.refTitle}</a>
          </div>}
          </div>
          <CountDoc/>
      <div style={{padding:'20px', paddingTop:'10px', borderRadius:'5px',marginTop:marginDiv ,boxShadow: '-5px 0px 10px -3px rgba(71, 10, 10, 0.1)'}}>
      <h1 style={{fontSize:'24px'}}>{idC.title}</h1>
      <button className='btnFill' href="#targetComponent" onClick={handleScroll}>Заполнить форму</button>
      <p style={{color:'#666666', fontSize:'18px'}} dangerouslySetInnerHTML={{ __html: textWithBreaks.join("") }}></p>
      </div>
      <div className="warning" >
         <p  style={{fontSize:'18px', color:'#666666 ', margin:'0', fontWeight:'bold'}}>Сервис UDocument, не собирает, не хранит и не обрабатывает персональные данные пользователей, для составления любого документа, регистрация на сайте не требуется.</p>
      </div>
      <div id="targetComponent" className='formCont'>
      <DocumentForm data={idC}/>
      </div>
      </div>
      <div className="articleRight">
        <CodeEditor />
      </div>
      </div>
    </div>
    </HelmetProvider>
  );}
  else {
    return (
      <LoadingScreen/>
    )
  }
}
}
export {CommonForm};

