import React, { useState, useEffect } from 'react';
import '../css/CodeEditor.css'
import Block from '../js/Block'
import { Link } from 'react-router-dom';

const  CodeEditor = () => {

const [articles, setArticles] = useState([
  { id: 1, title: 'React Hooks' }
]);

const [searchTerm, setSearchTerm] = useState('');

// const [findTitle, setFindTitle] = ('')

useEffect(() => {
  fetch('/article.json')
    .then(response => response.json())
    .then(data => setArticles(data.articles));
}, []);
// const filteredArticles = name.filter((article) =>
// article.title.toLowerCase().includes(findTitle.toLowerCase())
// );

const handleChangeName = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredArticles = articles.filter((article) =>
    article.title.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <div className='codeCont'> 
        <div>
        <div className='inpHeader'>
            <h2 className='hCode' >Введи название документа который необходимо создать, или выбери из предложенных</h2>
        </div>
        <div className="inpCont">
            <input className='inputInp'
            placeholder="Введи название документа"
         type="text" value={searchTerm} onChange={handleChangeName}/>
        <ul>
        {searchTerm && (filteredArticles.slice(0,4).map((article) => (
          <Link target="_blank" key={article.id} to={`/document/${article.id}`} style={{color:'#000000'}}>
          <li style={{marginTop:'20px'}} key={article.id}>{article.title}</li>
          </Link>
        )))}
      </ul>
      <Block/>
    </div>
    </div>
    </div>
  );
};

export default CodeEditor;