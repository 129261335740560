import '../css/LoginFoot.css'
import { useState } from 'react'
import Terms from './Terms'

const LoginFoot = () => {

    const [visibleTerms, setVisibleTerms] = useState(false)

    const onVisible = () => {
        setVisibleTerms(!visibleTerms)
    }

    return (
        <div>
            {visibleTerms ?
            <div className="divTerms">
              <Terms onClick={onVisible}/>
            </div>:<div/>}
            <div className="loginFootCont" >
            <div className="bottomLoginFoot">
                 <p style={{fontWeight:'bold', margin:"0px"}}>© UDocument 2020 - 2023</p>
                 <p onClick={onVisible} style={{margin:'0px', fontSize:'14px', cursor:'pointer', textDecoration:'underline'}}>Условия использования</p>
            </div>
             <div className='contRekviz'>
                 <p className='rekvis'>Услуги оказывает: Сушковa Татьяна Сергеевна, ИНН: 100117257353</p>
                 <p className='rekvis'>Адрес: г. Костомукша ул. Октябрьская д.2 п.7</p>
                 <p className='rekvis'>Эл. почта: support@udocument.online, тел. +7(995)-171-3100</p>
             </div>
             </div>
        </div>
    )
}

export {LoginFoot}