import '../css/LoadingScreen.css'

const LoadingScreen = () => {
    return (
        <div className='contLoading'>
            <p style={{fontWeight:"bold", fontSize:"78px", color:"#b009aa"}}>Loading...</p>
        </div>
    )
}

export {LoadingScreen}