import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import '../css/RightArticle.css'

const RightArticle = () => {
    const [documentNew, setDocumentNew] = useState([
        {
            "id": "1",
            "title": "", 
            "content": ""
          }])

    const [visibleCount, setVisibleCount] = useState(3)
    const handleShowMoreClick = () => {
      setVisibleCount((prevCount) => prevCount + 3);
    };


    useEffect(() => {
        fetch('/allArticles.json')
          .then(response => response.json())
          .then(data => setDocumentNew(data.articles));
      }, []);



    return (
        <div>
        <h2 className='hRightArticle'>Полезные статьи и материалы:</h2>
        {documentNew.slice(0, visibleCount).map((articles, index) => (
        <Link key={index+0.5} style={{textDecoration:'none'}} to={`/article/${documentNew[index].id}`}>
        <div className='contRightArticle'>
          <div className='imgCont'>
         <img className='imgRight' src={`/image/${documentNew[index].img}`} alt='?'/>
          <h1 key={index+0.3} className='titleRight' >{documentNew[index].title}</h1>
          </div>
          <p key={index+0.2} className='contentRight' dangerouslySetInnerHTML={{ __html: documentNew[index].content.slice(0,200)+'...'}}></p>
        </div>
        </Link>
          ))}
        
        {visibleCount < documentNew.length && (
        <div className='BtnRightArticle' onClick={handleShowMoreClick}>Показать ещё</div>
      )}

        </div>
    )
}

export default RightArticle