import '../css/LeftHome.css'
import { Link } from 'react-router-dom'
// import logoHead from '../image/logoHead.png'

const LeftHome = ({unVisible}) => {

    return (
        <div>
            <div className='contLeftHome'>
            <Link to='/' style={{textDecoration:'none'}}>
                {/* <img src={logoHead} alt='UDocument' style={{width:"90%", height:'90px'}}/> */}
                 <h1 className='headLeft' >UDocument</h1>
                 <p  style={{margin:'0', color:'#666666', fontSize:'15px',fontWeight:'bolder'}}>Создание юридических документов онлайн</p>
             </Link>
             <div className='contH'>
            <h4 style={{color:'#000000', fontSize:'26px', margin:'0px'}}>Юристы в прошлом.</h4>
            <p style={{color:'#666666', fontSize:'18px'}}>Теперь вы можете создавать любые документы - заявления, возражения, договоры - онлайн, не выходя из дома и без переплаты за услуги юристов. </p>
            </div>
            <div className='contH'>
            <h4 style={{color:'#000000', fontSize:'26px', margin:'0px'}}>Что мы предлагаем?</h4>
            <p style={{color:'#666666', fontSize:'18px'}}>UDocument - предоставляет возможность легко и быстро создавать официальные документы, которые будут иметь юридическую силу и могут использоваться в различных правовых процедурах.</p>
            </div>
            <div className='contH' style={{marginBottom:'0px'}}>
            <h4 style={{color:'#000000', fontSize:'26px', margin:'0px'}}>Никаких сложностей.</h4>
            <p style={{color:'#666666', fontSize:'18px'}}>Нет необходимости обращаться к юристам - создавайте документы самостоятельно с помощью UDocument!</p>
            </div>
            {!unVisible?
            <div className='contLink'>
            <Link to='/allarticles' style={{color:'#000000'}}>
            <li style={{fontSize:'18px', margin:'0px'}}>Полезные статьи и материалы:</li>
            </Link>
            </div>:<div/>}
            </div>
        </div>
    )
}
export default LeftHome