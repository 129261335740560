import { useEffect } from 'react';
import '../css/AllArticle.css'
import { YMInitializer } from 'react-yandex-metrika';
import LeftHome from './LeftHome';
import RightDocument from './RightDocument';

function AllDocuments() {
  const { innerWidth: width} = window;
  useEffect(() => {
    if (width>900){
    window.scrollTo(0, 0)};})

  return (
    <div>
       <YMInitializer accounts={[93382595]} options={{webvisor: true, ecommerce:"dataLayer",clickmap:true, trackLinks:true, accurateTrackBounce:true,}} />
      <div className='allArt'>
        <div className='leftAllArt'>
        <LeftHome />
        </div>
        <div className='rightAllArt'>
        <RightDocument/>
        </div>
      </div>
    </div>
  );
}

export {AllDocuments};