import React, { useState, useEffect } from 'react';
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {AppCont} from './js/AppCont';
import {CommonForm} from './js/CommonForm'
import {AllDocuments} from './js/AllDocuments'
import { Article } from './js/Article';
import {DocumentFormPaid} from './js/DocumentFormPaid.jsx'
import { AllArticle } from './js/AllArticle';
import { LoginFoot } from './js/logInFoot';
import { LoadingScreen} from './js/LoadingScreen'


function App() {

    const [idArt, setIdArt] = useState(null)
    const [art, setArt] = useState(null)

    useEffect(() => {
      console.log("Запуск")
        fetch('/article.json')
          .then(response => response.json())
          .then(data => setIdArt(data.articles));
        
          fetch('/allArticles.json')
          .then(response => response.json())
          .then(data => setArt(data.articles));

          //Счетчик Яндекс метрики на время проведенное на сайте
          const hasFunctionBeenCalled = localStorage.getItem('functionCalled11');
          if (!hasFunctionBeenCalled) {
            // Вызов функции через 20 секунд
            const timeoutId = setTimeout(() => {
              ym(93382595,'reachGoal','time_left');
              localStorage.setItem('functionCalled11', 'true');
            }, 20000);
      
            // Очистка таймера при размонтировании компонента (если требуется)
            return () => clearTimeout(timeoutId);
          }
    
      }, []);

      
if (idArt && art) {
  return (
    <>
    <YMInitializer accounts={[93382595]} options={{webvisor: true, ecommerce:"dataLayer", clickmap:true, trackLinks:true, accurateTrackBounce:true}} />
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<AppCont />} />
        {idArt && idArt.map((article, index) => 
        <Route key={index} path={`/document/${idArt[index].id}`} element={<CommonForm data={idArt[index]}/>} />
        )
}
{art && art.map((article, index) => 
        <Route key={index} path={`/article/${art[index].id}`} element={<Article data={art[index]}/>} />
        )
}
      <Route path='/alldocuments' element={<AllDocuments />}/>
      <Route path='/allarticles' element={<AllArticle unVisible={true}/>}/>
      <Route path='/successpaid' element={<DocumentFormPaid/>}/>
      </Routes>
    </BrowserRouter>
    <LoginFoot/>
    </>
  );
} else {
return (
  <LoadingScreen/>
)}
}

export default App;




